<script setup lang="ts">
import { ParagraphVideo, MediaImageFragment, MediaRemoteVideo } from '~/api/graphql/generated'

const props = defineProps<{ entity: ParagraphVideo }>()

const data = computed(() => ({
  title: props.entity?.fieldTitle ?? '',
  description: props.entity?.fieldDescription?.processed ?? '',
  image: props.entity?.fieldImage?.entity as MediaImageFragment,
}))
const videoUrl = computed(() => {
  const video = props.entity?.fieldVideo?.entity as MediaRemoteVideo
  return video?.fieldMediaOembedVideo ?? ''
})

const { controller, iframeSrc } = useModalVideo(videoUrl.value)
</script>
<template>
  <Section>
    <TeaserSingle :data="data">
      <Button secondary icon="media" @click="controller.open">
        {{ $t('media.playButton') }}
      </Button>
      <ModalVideo :controller="controller">
        <iframe v-if="iframeSrc" :src="iframeSrc" frameborder="0" allowfullscreen />
      </ModalVideo>
    </TeaserSingle>
  </Section>
</template>
